import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
import SidebarReviewRotator from "../components/sidebarReviewRotator";
import FluidImage from "../components/fluidImage";
import FixedImage from "../components/fixedImage";
import Dentist from "../components/dentist";
import Dentists from "../components/dentists";
import WPLink from "../components/wpLink";
import Hours from "../components/hours";
import formatPhone from "../helpers/formatPhone";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import prepareSchemaHours from "../helpers/prepareSchemaHours";

import * as styleSheet from "./location.module.scss"

const LocationTemplate = (props) => {

	const [schemaData, setSchemaData] = useState(null);

	const {
		data: {
			location,
			location: {
				title,
				birdeyeLocation,
				birdeyeLocation: {
					phone: mainPhone,
					location: {
						city,
						address1: addressLine1,
						address2: addressLine2,
						zip,
						state,
						lat,
						lng
					},
					socialProfileURLs: {
						facebookUrl: facebookPageUrl,
						instagramUrl: instagramHandle
					},
					hoursOfOperations,
					timezone
				},
				ACFLocation: {
					googlePlaceId,
					bookMyAppointmentLink,
					locationInfo: {
						left: {
							dentists,
							insurancesHeading,
							insurances,
							reviewHeading,
							reviews
						},
						right: {
							allServices,
							featuredServices,
							introContent: {
								heading: introHeading,
								blurb: introBlurb,
								link: introLink
							},
							contentHeading,
							locationContent: content,
							callout: {
								backgroundImage: calloutBG,
								blurb: calloutBlurb,
								heading: calloutHeading,
								preHeading: calloutPreHeading,
								link: calloutLink
							},
							patientInfo: {
								heading: patientInfoHeading,
								blurb: patientInfoBlurb,
								paperworkLink
							}
						}
					}
				}
			},
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
							headerLogo
						}
					}
				}

			},
			home: {
				ACFHomePage: {
					hero: {
						callouts: {
							callout1: {
								preHeading: homeCalloutPreHeading,
								heading: homeCalloutHeading,
								blurb: homeCalloutBlurb,
								link: homeCalloutLink,
								backgroundImage: homeCalloutBG 
							}
						}
					}
				}
			},
			rightArrowYellowOffsite,
			rightArrowYellow,
			locationsPage,
			introBGMobile,
			introBGTablet,
			introBGDesktop,
			introBGXL,
			arrowBlue,
			redStar,
			arrowLightGray
		}
	} = props;

	function directionLink() {
		if (!lat || !lng) {
			return;
		}

		let placeIdParam = '';

		if (googlePlaceId) {
			placeIdParam = `&destination_place_id=${googlePlaceId}`;
		}

		return <a className={styleSheet.directions} href={`https://www.google.com/maps/dir/?api=1&destination=${lat}, ${lng}${placeIdParam}`} target="_blank" rel="noopener noreferrer">Get Directions <FixedImage image={rightArrowYellowOffsite} passedClass={styleSheet.icon} /></a>;
	}

	useEffect(() => {
		const locHours = prepareSchemaHours(birdeyeLocation.hoursOfOperations);
		const newSchema = `{
			"@context": "https://schema.org",
			"@type": "Dentist",
			"name": "${birdeyeLocation.name}",
			"url": "${props.location.href}",
			"telephone": "${birdeyeLocation.phone}",
			"address": {
				"@type": "PostalAddress",
				"streetAddress": "${birdeyeLocation.location.address1} ${birdeyeLocation.location.address2}",
				"addressLocality": "${birdeyeLocation.location.city}",
				"addressRegion": "${birdeyeLocation.location.state}",
				"postalCode": "${birdeyeLocation.location.zip}",
				"addressCountry": "US"
			},
			"geo": {
				"@type": "GeoCoordinates",
				"latitude": ${birdeyeLocation.location.lat},
				"longitude": ${birdeyeLocation.location.lng}
			},
			"openingHours": [${locHours}]
		}`;

		setSchemaData(newSchema);
	}, []);

	return (
		<Layout>
			<Seo post={location} />
			<Helmet>
				{schemaData && <script type="application/ld+json">
					{schemaData}
				</script>}
			</Helmet>
			<div className={styleSheet.top}>
				<div className={styleSheet.left}>
					{ headerLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={headerLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
					<div className={styleSheet.mobilebreadcrumbs}>{ locationsPage && locationsPage.nodes && locationsPage.nodes.length > 0 && <Link className={styleSheet.loclink} to={locationsPage.nodes[0].link}>Locations</Link>} <span className={styleSheet.locationname} dangerouslySetInnerHTML={{__html: title}}></span></div>
					<div className={styleSheet.locationinfo}>
						<h1 className={styleSheet.title} dangerouslySetInnerHTML={{__html: title}}></h1>
						{ hoursOfOperations && timezone && <Hours locationHours={hoursOfOperations} utcOffset={timezone} passedStyles={styleSheet} /> } 
						{ addressLine1 && <span className={styleSheet.address}>{addressLine1}</span> }
						{ addressLine2 && <span className={styleSheet.address}>{addressLine2}</span> }
						{(city && state && zip) && <span className={styleSheet.address}>{city}, {state} {zip}</span>}
						{ directionLink() }
						{ mainPhone && <a className={styleSheet.phone} href={`tel:${mainPhone}`}>{formatPhone(mainPhone)}</a>}
						{ ( instagramHandle || facebookPageUrl ) && (
							<div className={styleSheet.social}>
								{ facebookPageUrl && (
									<a className={styleSheet.socialitem} href={facebookPageUrl} target="_blank" rel="noopener noreferrer">
										<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
											<g fill="none" fillRule="evenodd">
												<g fill="#70C4EA">
													<g>
														<g>
															<g>
																<path d="M14.172 0H.828C.37 0 0 .37 0 .828v13.344c0 .457.37.828.828.828h7.184V9.191H6.057V6.927h1.955v-1.67c0-1.937 1.183-2.992 2.912-2.992.827 0 1.539.062 1.746.09v2.024l-1.198.001c-.94 0-1.122.447-1.122 1.102v1.445h2.241L12.3 9.191h-1.95V15h3.823c.457 0 .828-.37.828-.828V.828C15 .37 14.63 0 14.172 0" transform="translate(-141 -448) translate(35 45) translate(105 180) translate(1 223)"/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</svg>
									</a>
								)}
								{ instagramHandle && (
									<a className={styleSheet.socialitem} href={`https://instagram.com/${instagramHandle}`} target="_blank" rel="noopener noreferrer">
										<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
											<g fill="none" fillRule="evenodd">
												<g fill="#70C4EA">
													<g>
														<g>
															<g>
																<path d="M79.408.045c-.799.037-1.344.163-1.82.349-.494.191-.912.448-1.33.865-.417.417-.673.835-.864 1.328-.186.477-.312 1.022-.349 1.82-.037.8-.045 1.056-.045 3.093s.008 2.292.045 3.092c.037.799.163 1.344.349 1.82.191.494.447.913.865 1.33.417.416.835.672 1.328.864.478.186 1.022.312 1.82.349.8.036 1.056.045 3.093.045s2.292-.008 3.092-.045c.798-.037 1.344-.163 1.82-.349.494-.192.912-.448 1.329-.865.417-.417.673-.835.865-1.328.186-.477.313-1.022.348-1.82.037-.801.045-1.056.045-3.093s-.008-2.292-.045-3.092c-.036-.799-.162-1.344-.348-1.82-.192-.494-.448-.912-.865-1.33-.417-.417-.835-.673-1.328-.864-.477-.186-1.023-.313-1.82-.349C84.793.01 84.537 0 82.5 0c-2.036 0-2.292.008-3.092.045m.062 13.56c-.731-.033-1.129-.155-1.393-.258-.35-.136-.6-.299-.863-.561-.262-.262-.425-.512-.56-.862-.104-.264-.225-.662-.259-1.393-.036-.79-.043-1.028-.043-3.03 0-2.004.008-2.24.043-3.032.034-.731.156-1.128.258-1.393.137-.35.3-.6.561-.862.263-.263.512-.426.863-.561.264-.103.662-.225 1.393-.258.79-.037 1.028-.044 3.03-.044s2.24.008 3.03.044c.732.033 1.129.156 1.394.258.35.135.6.298.862.56.263.263.425.513.561.863.103.264.225.662.258 1.393.037.791.044 1.028.044 3.031s-.007 2.24-.044 3.031c-.033.731-.155 1.129-.258 1.393-.136.35-.298.6-.56.862-.263.262-.513.425-.863.56-.264.104-.662.226-1.393.259-.79.036-1.028.043-3.031.043s-2.24-.007-3.03-.043m6.134-10.109c0 .497.402.9.9.9.496 0 .9-.402.9-.9 0-.496-.403-.9-.9-.9s-.9.403-.9.9M78.649 7.5c0 2.127 1.724 3.851 3.85 3.851 2.128 0 3.852-1.724 3.852-3.851S84.627 3.649 82.5 3.649 78.649 5.373 78.649 7.5M80 7.5C80 6.12 81.12 5 82.5 5S85 6.12 85 7.5 83.88 10 82.5 10 80 8.88 80 7.5" transform="translate(-216 -448) translate(35 45) translate(105 180) translate(1 223)"/>
															</g>
														</g>
													</g>
												</g>
											</g>
										</svg>
									</a> 
								)}
							</div>
						)}
					</div>
					{ bookMyAppointmentLink && <WPLink link={bookMyAppointmentLink} passedClass={styleSheet.link} /> }
					{  dentists && dentists.length > 0 && (
						<div className={styleSheet.dentistcontainer}>
							{ dentists && dentists.length > 1 && dentists.length > 0 && <Dentists dentists={dentists} arrowLightGray={arrowLightGray} /> }
							{ dentists && dentists.length === 1 && <Dentist dentist={dentists[0]} icon={rightArrowYellow} /> }
						</div>
					)}
				</div>
				<div className={ ( dentists && dentists.length > 0 ) ? styleSheet.right : styleSheet.rightnodoctor }>
					{ introBGMobile && <FluidImage image={introBGMobile} passedClass={styleSheet.bgmobile} loading={"eager"} /> }
					{ introBGTablet && <FluidImage image={introBGTablet} passedClass={styleSheet.bgtablet} loading={"eager"} /> }
					{ introBGDesktop && <FluidImage image={introBGDesktop} passedClass={styleSheet.bgdesktop} loading={"eager"} /> }
					{ introBGXL && <FluidImage image={introBGXL} passedClass={styleSheet.bgxl} loading={"eager"} /> }
					<div className={styleSheet.desktopbreadcrumbs}>{ locationsPage && locationsPage.nodes && locationsPage.nodes.length > 0 && <Link className={styleSheet.loclink} to={locationsPage.nodes[0].link}>Locations</Link>} <span className={styleSheet.locationname} dangerouslySetInnerHTML={{__html: title}}></span></div>
					{ introHeading && <div className={styleSheet.heading} dangerouslySetInnerHTML={{__html: introHeading}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></div> }
					{ introBlurb && <p dangerouslySetInnerHTML={{__html: introBlurb}} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-in"></p> }
					{ introLink && <WPLink link={introLink} icon={rightArrowYellow} passedClass={styleSheet.link} passedIconClass={styleSheet.icon} data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-in" /> }
				</div>
			</div>
			<div className={styleSheet.bottom}>
				<div className={ (dentists && dentists.length >= 1) ? styleSheet.left : styleSheet.leftnodentist}>
					<div className={styleSheet.insurance}>
						<span className={styleSheet.heading}>{ insurancesHeading ? insurancesHeading : "We accept the following insurance" }</span>
						<div className={styleSheet.insurancebottom}>
							{ insurances && insurances.map( (insurance, index) => {
								const image = insurance.ACFInsurance.logo;
								return image ? <FluidImage key={`insuranceimg-${index}`} image={image} passedClass={styleSheet.insuranceimage} /> : null;
							})}
						</div>
					</div>
					{ reviews && reviewHeading && (
						<div className={styleSheet.reviews}>
							<span className={styleSheet.heading}>{reviewHeading}</span>
							<SidebarReviewRotator reviews={reviews} redStar={redStar} />
						</div>
					)}
				</div>
				<div className={styleSheet.right}>
					<div className={styleSheet.locationcontent}>
						<div className={styleSheet.contentleft}>
							<span className={styleSheet.gettoknow}>{ contentHeading ? contentHeading : "Get to know our clinic." }</span>
							{ content && <div className="wpcontent" dangerouslySetInnerHTML={{__html: content}}></div> }
						</div>
						<div className={styleSheet.callout}>
							{ (homeCalloutPreHeading || calloutPreHeading) && (
								<div className={styleSheet.preheadcontainer}>
									<span className={styleSheet.preheading}>{calloutPreHeading ? calloutPreHeading : homeCalloutPreHeading}</span> 
								</div>
							)}
							{ (calloutHeading || homeCalloutHeading) && <div className={styleSheet.heading} dangerouslySetInnerHTML={{__html: calloutHeading ? calloutHeading : homeCalloutHeading }}></div> }
							{ (calloutBlurb || homeCalloutBlurb) && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: calloutBlurb ? calloutBlurb : homeCalloutBlurb}}></div> }
							{ (homeCalloutLink || calloutLink) && <WPLink link={calloutLink ? calloutLink : homeCalloutLink} passedClass={styleSheet.link} /> }
							{ (homeCalloutBG || calloutBG) && <FluidImage image={calloutBG ? calloutBG : homeCalloutBG} passedClass={styleSheet.bg} /> }
						</div>
					</div>
					{ (patientInfoHeading || patientInfoBlurb || paperworkLink) && (
						<div className={styleSheet.patientinfo}>
							{ patientInfoHeading && <span className={styleSheet.heading}>{patientInfoHeading}</span> }
							{ patientInfoBlurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: patientInfoBlurb}}></div> }
							{ paperworkLink && <WPLink link={paperworkLink} passedClass={styleSheet.link}  icon={rightArrowYellow} passedIconClass={styleSheet.icon} /> }
						</div>
					)}
					<div className={styleSheet.locationservices}>
						<div className={styleSheet.left}>
							<span className={styleSheet.heading}>Featured Services</span>
							<div className={styleSheet.servicescontainer}>
								{ featuredServices && featuredServices.map((service, _index) => {
									return (
										<div key={`ftsrvc-'${service.id}`} className={styleSheet.featuredservice}>
											{service.ACFService.serviceIcon && (
												<div className={styleSheet.iconrow}>
													<div className={styleSheet.iconcontainer}>
														<FluidImage image={service.ACFService.serviceIcon} passedClass={styleSheet.icon} /> 
													</div>
												</div>
											)}
											<Link to={service.link}><h2 className={styleSheet.title} dangerouslySetInnerHTML={{__html: service.title }}></h2></Link>
											{ service.ACFService.briefDescription && <p dangerouslySetInnerHTML={{__html: service.ACFService.briefDescription}}></p>}
										</div>
									)
								})}
							</div>
						</div>
						<div className={styleSheet.right}>
							<span className={styleSheet.heading}>All our services.</span>
							<ul className={styleSheet.list}>
								{ allServices && allServices.map((service, index) => {
									return (
										<li key={`lstsrvc-'${service.id}`} className={styleSheet.listitem}>
											<Link to={service.link} className={styleSheet.link}>
												<FixedImage passedClass={styleSheet.icon} image={arrowBlue} />
												<span className={styleSheet.title} dangerouslySetInnerHTML={{__html: service.title }}></span>
											</Link>	
										</li>
									)
								})}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`query ($id: String!) {
  location: wpLocation(id: {eq: $id}) {
    title
    seo {
      title
      metaDesc
      focuskw
      metaKeywords
      metaRobotsNoindex
      metaRobotsNofollow
      opengraphTitle
      opengraphDescription
      opengraphImage {
        altText
        sourceUrl
        srcSet
      }
      twitterTitle
      twitterDescription
      twitterImage {
        altText
        sourceUrl
        srcSet
      }
      canonical
      cornerstone
      schema {
        articleType
        pageType
        raw
      }
    }
		birdeyeLocation {
			businessId
			timezone
			hoursOfOperations {
				day
				isOpen
				workingHours {
					startHour
					endHour
				}
			}
			location {
				lat
				lng
				address1
				address2
				city
				state
				zip
			}
			name
			phone
			socialProfileURLs {
				facebookUrl
				instagramUrl
			}
		}
    ACFLocation {
			googlePlaceId
      bookMyAppointmentLink {
        target
        title
        url
      }
      locationInfo {
        left {
          dentists {
            ... on WpDentist {
              id
							ACFDentist {
								headshot {
									localFile {
										childImageSharp {
                    	gatsbyImageData(layout: FULL_WIDTH)
										}
                  }
								}
							}
              title
              link
            }
          }
          insurancesHeading
          insurances {
            ... on WpInsurance {
              id
              ACFInsurance {
                logo {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                    publicURL
                    extension
                  }
                }
              }
            }
          }
          reviewHeading
          reviews {
            ... on WpReview {
              id
              title
              ACFReview {
                stars
                location
                blurb
              }
            }
          }
        }
        right {
          allServices {
            ... on WpService {
              id
              link
              title
            }
          }
          featuredServices {
            ... on WpService {
              id
              title
              link
              ACFService {
                briefDescription
                serviceIcon {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                    publicURL
                    extension
                  }
                }
              }
            }
          }
          introContent {
            blurb
            heading
            link {
              target
              title
              url
            }
          }
          contentHeading
          locationContent
          callout {
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                }
                publicURL
                extension
              }
            }
            blurb
            heading
            preHeading
            link {
              target
              title
              url
            }
          }
          patientInfo {
            heading
            blurb
            paperworkLink {
              target
              title
              url
            }
          }
        }
      }
    }
  }
  options: wp {
    headerSection {
      ACFHeaderOptions {
        mainNavigationSettings {
          headerLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 420, layout: CONSTRAINED)
              }
              publicURL
              extension
            }
          }
        }
      }
    }
  }
  home: wpPage(isFrontPage: {eq: true}) {
    ACFHomePage {
      hero {
        callouts {
          callout1 {
            preHeading
            heading
            blurb
            link {
              target
              title
              url
            }
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400, placeholder: NONE, layout: CONSTRAINED)
                }
                publicURL
                extension
              }
            }
          }
        }
      }
    }
  }
  rightArrowYellowOffsite: file(name: {eq: "arrow_offsite"}) {
    childImageSharp {
      gatsbyImageData(width: 10, layout: FIXED)
    }
  }
  rightArrowYellow: file(name: {eq: "arrow"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
  introBGMobile: file(name: {eq: "interior-header_overlay1"}) {
    childImageSharp {
      gatsbyImageData(width: 699, layout: CONSTRAINED)
    }
  }
  introBGTablet: file(name: {eq: "interior-header_overlay1"}) {
    childImageSharp {
      gatsbyImageData(width: 550, layout: CONSTRAINED)
    }
  }
  introBGDesktop: file(name: {eq: "interior-header_overlay1"}) {
    childImageSharp {
      gatsbyImageData(width: 840, layout: CONSTRAINED)
    }
  }
  introBGXL: file(name: {eq: "interior-header_overlay1"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  locationsPage: allWpPage(
    filter: {template: {templateName: {eq: "Locations Page"}}}
  ) {
    nodes {
      link
    }
  }
  arrowBlue: file(name: {eq: "arrow_blue"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
  redStar: file(name: {eq: "Star"}) {
    childImageSharp {
      gatsbyImageData(width: 20, layout: FIXED)
    }
  }
  arrowLightGray: file(name: {eq: "arrow_light_gray"}) {
    childImageSharp {
      gatsbyImageData(width: 10, layout: FIXED)
    }
  }
}
`

export default LocationTemplate;
