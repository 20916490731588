import React from "react";
import { Link } from "gatsby";
import FixedImage from "../fixedImage"
import { GatsbyImage } from "gatsby-plugin-image";

import * as styleSheet from "./index.module.scss"

const Dentist = ({  dentist, icon = null }) => {

	const {
		id,
		ACFDentist: {
			headshot
		},
		title,
		link
	} = dentist;

  	return (
        <Link key={id} to={link} className={styleSheet.dentist}>
			{ headshot ? <GatsbyImage
                image={headshot.localFile.childImageSharp.gatsbyImageData}
                className={styleSheet.image}
				placeholder="none"
                alt={title}
                loading={"eager"} /> : <div className={styleSheet.noimage}></div> }
			<span className={styleSheet.title} dangerouslySetInnerHTML={{__html: title}}></span>
			<span className={styleSheet.view}>View profile { icon && <FixedImage image={icon} /> }</span>
		</Link>
    );
}

export default Dentist;
